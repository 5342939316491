@import "src/_mixin.scss";

.textDisplay {
  @include poppins_font(18px, 26px, 400, $text-dark-grey);
  margin: 0;

  &_bold {
    font-weight: 600;
  }

  @include media('mobile') {
    font-size: 16px;
  }
}
