@import "src/_mixin.scss";
$size-default: 48px;

.fabButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: $size-default;
  height: $size-default;
  border-radius: 50%;
  border-width: 0;
  background-color: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);

  &:disabled {
    cursor: not-allowed;
  }
}

.fabButton_cta {
  background-color: $background-cta;

  &:hover {
    background-color: $cta-hover;
  }

  &:focus {
    background-color: $cta-hover;
  }

  &:active {
    background-color: $cta-active;
  }

  &:disabled {
    background-color: $background-lightest;
    color: $text-light-grey;
  }
}

.fabButton_primary {
  color: #fff;
  background-color: $primary;

  &:hover {
    background-color: $primary-darker;
  }

  &:focus {
    background-color: $primary-darker;
  }

  &:active {
    background-color: $primary-darkest;
  }

  &:disabled {
    background-color: $background-lightest;
    color: $text-light-grey;
  }
}

.fabButton_secondary {
  color: $primary;
  background-color: #fff;

  &:hover {
    border-color: $primary;
  }

  &:focus {
    border-color: $primary;
  }

  &:active {
    color: $text-dark-grey;
  }

  &:disabled {
    color: $text-light-grey;
  }
}

.fabButton_danger {
  color: #fff;
  background-color: $error;
}
