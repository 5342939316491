@import "src/_mixin.scss";

.complexLoginForm {
  &__form {
    text-align: center;
    will-change: transform; // Hack to fix safari rendering optimization

    @include media('mobile') {
      will-change: auto;
    }
  }

  &__formContent {
    max-width: 425px;
    margin: 0 auto;
  }

  &__who {
    margin-bottom: 35px;
  }

  &__tos,
  &__tos a {
    @include lato_font(12px, 15px, 400, $silver-chalice);
    padding: 0;
  }

  &__tos {
    margin: 15px 0 0;

    a {
      text-decoration: underline;
    }

    b {
      color: $text-grey;
    }
  }

  &__loginControls {
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
    align-items: center;

    .form__row {
      margin-bottom: 0;
    }
  }

  &__resetNotification {
    margin: 10px 0 20px;
    padding: 10px;
    background-color: #d9edf7;
    color: #31708f;
  }

  &__selectStep {
    display: flex;
    margin: auto;
    justify-content: space-around;
  }

  &__inputs {
    margin-top: 35px;
  }

  &__inputs {
    text-align: left;
  }

  &__input {
    margin: 0;
    padding-left: 40px !important;
  }

  &__inputWrap {
    position: relative;

    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 40px;
      height: 40px;
      background: left 10px center no-repeat;
      background-size: 15px;
    }

    &_phone:before {
      background-image: url('../../common/Chat/img/phone.svg');
    }

    &_email:before {
      background-image: url('../../common/Chat/img/email.svg');
    }
  }

  &__submit_wizard {
    display: none;

    @include media('mobile') {
      display: block;
    }
  }

  &__smsSent {
    &_login {
      margin-bottom: 35px;
    }
  }
}
