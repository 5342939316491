@import "src/_mixin.scss";

.navigation {
  display: flex;
  margin-left: auto;

  &__list {
    display: flex;
    margin: 0;
    padding: 0;

    @include media('tablet') {
      display: none;
    }
  }

  &__item {
    display: flex;
    margin-right: 10px;
    align-items: center;

    &_complexLogin {
      @include media('smallHeader') {
        display: none;
      }
    }
  }

  &__link {
    @include button_link(15px, $desktop-header-height);
    padding: 0 20px;
    transition: background-color .2s;

    &_provider {
      color: #fff;

      &:hover,
      &:focus,
      &:active {
        color: $text-light-grey;
      }
    }

    @include media('smallHeader') {
      padding: 0 10px;
    }

    &_sp {
      position: relative;
      padding-left: 35px;
      margin-left: 15px;
      color: $text-grey;

      &:hover {
        color: $text-dark-grey;
      }

      &:before {
        @include border-on('left');
        content: '';
        display: block;
        position: absolute;
        height: 30px;
        top: 20px;
        left: 0;
      }

      @include media('smallHeader') {
        padding: 0 0 0 15px;
        margin: 0;
      }
    }
  }

  &__greeting {
    color: white;
    margin-bottom: 0.25rem;
  }

  &__account {
    font-size: 12px;
    line-height: 14px;
  }

}
