@import "src/_mixin.scss";

.flashMessage {
  @include lato_font(13px, 16px, 700, #fff);
  height: 0;
  padding: 12px 15px 12px 45px;
  border-radius: $global-border-radius;
  transform: translateY(-20px);
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  filter: drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.1));

  @include media('mobile') {
    padding-right: 30px;
  }

  &_show {
    height: auto;
    opacity: 1;
    transform: translateY(0);
  }

  &_success {
    // Yellow is a new green
    background: #85d92b url('./img/success-icon.svg') left 15px center no-repeat;
    color: $white;
  }

  &_error {
    // copied from oldCommonStyles.scss
    background: #f3d9d9 url('./img/error-icon.svg') left 15px center no-repeat;
    border: 1px solid #ff0202;
    color: $text-dark-grey;
    font-weight: 400;
  }

  &_info {
    background: $early-dawn url('./img/success-icon-info.svg') left 15px center no-repeat;
    .textP3 {
      color: $text-dark-grey;
    }
    .flashMessage__close {
      &:before,
      &:after {
        background-color: $text-dark-grey;
      }
    }
  }

  &__wrap {
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    z-index: -1;

    &_show {
      z-index: 1100;
    }
  }

  &__container {
    @include container;
  }

  &__close {
    @include button_close(10px, #fff, 2px);
    position: absolute;
    right: 15px;
    top: 10px;
  }

  &_error &__close {
    &::after,
    &::before {
      background-color: $text-dark-grey;
    }
  }

  &__text {
    color: currentColor;
  }
}
