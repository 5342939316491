@import "src/_mixin.scss";

.footer {
  background: transparent;

  &_hidden {
    display: none !important;
  }

  &__row {
    display: flex;
    padding: 20px 0;
    border-top: 1px solid $border-dark;

    @include media('mobile') {
      display: block;
    }

    &_proLinks {
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      @include media('mobile') {
        flex-wrap: wrap;
        padding-bottom: 0;
        align-items: flex-start;
      }
    }

    &_userLinks {
      justify-content: space-between;
    }

    &_socials {
      position: relative;
      flex-grow: 1;

      @include media('tablet') {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-flow: column-reverse;
        padding-top: 0;
        border-width: 0;
      }
    }
  }

  &__column {
    margin-right: 20px;

    @include media('mobile') {
      margin-right: 0;
      margin-bottom: 35px;
    }

    &_apps {
      display: flex;

      @include media('mobile') {
        display: none;
      }
    }

    a,
    span {
      display: block;
    }
  }

  &__logo {
    margin-right: 20px;
    width: 60px;
  }

  &__blog {
    font-size: 13px;
    color: $text-grey;
    font-weight: 700;

    &:hover {
      color: $text-dark-grey;
    }
  }

  &__aboutUs,
  &__help {
    @include media('mobile') {
      margin-bottom: 35px;
      margin-right: 0;
    }
  }

  &__copyright {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    z-index: -1;
    font-size: 13px;

    @include media('content') {
      position: static;
    }

    @include media('mobile') {
      position: static;
      width: 100%;
      margin-top: 20px;
    }
  }

  &__partners {
    align-self: end;
    display: flex;
    align-items: center;

    @include media('tablet') {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $border-dark;
    }

    @include media('mobile') {
      justify-content: space-between;
    }
  }

  &__title {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    font-family: $PoppinsFont;
    color: $text-grey;
  }

  &__links {
    column-count: 2;

    &_help {
      column-count: 1;
    }

    @include media('mobile') {
      column-count: 1;
    }
  }

  &__item {
    @include lato_font(13px, 24px, 500, $text-grey);
    display: block;
    margin-bottom: 0;
    margin-right: 25px;
    white-space: nowrap;

    a {
      @include lato_font(13px, 24px, 500, $text-grey);
    }

    &:last-child {
      margin-right: 0;
    }

    &_link:hover {
      color: $text-grey;
      text-decoration: underline;
    }

    @include media('mobile') {
      display: block;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  &__olx {
    margin-left: 15px;
    opacity: 0.75;
    color: $text-grey;
    font-size: 13px;
    line-height: 30px;

    &:first-child {
      margin-left: 0;
    }

    @include media('mobile') {
      margin: 0 !important;
      opacity: 1;

      &:before {
        opacity: 0.5;
      }
    }

    &:hover {
      color: $text-grey;
      text-decoration: underline;
      opacity: 1;
    }

    &:before,
    & > svg {
      display: inline-block;
      content: '';
      width: 16px;
      height: 16px;
      margin-right: 5px;
      background: transparent center no-repeat;
      background-size: 16px;
      vertical-align: sub;
      filter: grayscale(1);
      transition: 0.2s;
    }

    & > svg {
      width: auto;
      height: auto;
    }

    &:hover:before {
      filter: none;
    }

    &:hover > svg {
      color: #002f34;
    }

    &_otodom:before {
      background-image: url('~common/icons/otodom.svg');
    }

    &_otomoto:before {
      background-image: url('~common/icons/otomoto.png');
    }

    &_olx:before,
    &_carsmile:before {
      display: none;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    flex-grow: 1;

    @include media('mobile') {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__social {
    display: inline-block;
    margin-right: 35px;
    line-height: 24px;
    filter: grayscale(1) brightness(0.9);
    opacity: 0.3;
    transition: 0.2s;
    background-repeat: no-repeat;

    &:hover {
      filter: none;
      opacity: 1;
    }

    @include media('mobile') {
      margin-right: 25px;
    }

    &_facebook {
      width: 24px;
      height: 24px;
      background-image: url("~common/icons/fb.svg");
    }

    &_youtube {
      width: 28px;
      height: 20px;
      background-image: url("~common/icons/youtube.svg");
    }
  }

  & &__appButton {
    display: flex;
    margin-top: 10px;
    width: max-content;
    background-color: $dark-grey;
    border-color: $dark-grey;
    font-family: $LatoFont;
    text-transform: none;
    color: $white;
  }

  &__new-tos > sup {
    color: $amaranth;
  }
}
