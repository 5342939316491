@import "src/_mixin.scss";

.input {
  @include lato_font(15px, 18px, 400, $text-dark-grey);

  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid $border-dark;
  border-radius: 4px;
  transition: border-color .2s;

  &:focus {
    @include focus_state;
    border-color: $primary;
  }

  &_error {
    border-color: $error-color;

    &:focus {
      border-color: $error-color;
    }
  }

  &_iconic {
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0.5rem center;
    background-size: 20px 20px;
  }

  &_big {
    padding-top: 12px;
    padding-bottom: 13px;
  }

  &:disabled {
    color: $silver-chalice;
    border-color: $border-light;
    background-color: $background-lightest;
  }

  &::placeholder {
    color: $text-light-grey;
  }
}
