@import "src/_mixin.scss";

.expandButton {
  @include button_link;

  &__icon {
    position: relative;
    top: 0;
    margin-left: 10px;
    vertical-align: bottom;
    transition: top .2s;

    & > svg {
      vertical-align: bottom;
      width: 20px;
      height: 20px;
    }
  }

  &:hover &__icon {
    top: 3px;

    & > svg path {
      fill: $link-color-hover;
    }
  }

  &_expanded &__icon > svg {
    transform: rotate(180deg);
  }

  &_expanded:hover &__icon {
    top: -3px;
  }
}
