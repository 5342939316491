@import "src/_mixin.scss";

.coupons__list {
  display: grid;
  grid-gap: 2rem;

  @include min_media('medium') {
    grid-template-columns: 1fr 1fr;
    align-items: start;
  }
}
