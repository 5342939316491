@import "src/_mixin.scss";

.closeButton {
  @include focus_state;;
  padding: 0;
  height: 24px;
  width: 24px;
  background-image: url('./img/close.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
