@import "src/_mixin.scss";

.userReviewLinkHandler {
  &__modal {
    text-align: center;
  }
  &__modalHeader{
    margin-bottom: 15px;
  }
  &__modalButton {
    margin-top: 25px;
  }
}
