@import "src/_mixin.scss";

.textMeta {
  @include lato_font(10px, 14px, 400, $text-grey);
  margin: 0;

  &_bold {
    font-weight: 700;
  }

  &_big {
    font-size: 13px;
    line-height: 17px;
  }

  &_light {
    color: $text-light-grey;
  }
}
