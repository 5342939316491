@import "src/_mixin.scss";

.snackbarContainer {
  display: flex;
  justify-content: center;
  max-height: 100%;
  position: fixed;
  top: 60px;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  z-index: 1010;
  height: auto;
  width: 100%;
  @include min_media('medium') {
    top: 80px;
    width: auto;
  }
}
