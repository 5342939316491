
@import "src/_mixin.scss";

.dropdownMenu {
  &_dark {
    .dropdownMenu {
      &__button {
        color: $white;
        &_open,
        &_open:hover {
          background-color: $grey;
        }
        &:focus {
          color: $white;
        }
        &:hover {
          color: $silver-chalice;
          &::after {
            border-color: $silver-chalice;
          }
        }
        &::after {
          border-color: $white;
        }
      }
    }
  }
  &__button {
    @include button_link(16px, 30px);
    @include dropdown_arrow;

    position: relative;
    padding: 19px 35px 20px 15px;
    border-radius: 0;

    &_open,
    &_open:hover {
      color: $dark-grey;
      background-color: $grey-settings;

      &::after {
        transform: rotate(225deg);
        border-color: $dark-grey;
      }
    }
  }

  $dropdown-transition-duration: 0.2s;
  &__dropdownWrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    transform: scale(0);
    transition: transform 0s $dropdown-transition-duration;

    &_open {
      transform: scale(1);
      transition: transform 0s 0s;
    }
  }

  &__dropdown {
    padding: 45px 0;
    background-color: $grey-settings;
    opacity: 0;
    transform: translateY(-10px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);

    transition: opacity $dropdown-transition-duration, transform $dropdown-transition-duration;

    &_open {
      opacity: 1;
      transform: translateY(0);
    }
    &_dark {
      background-color: $grey;
    }
  }

  &__container {
    @include container;
  }
}
