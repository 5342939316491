@import "src/_mixin.scss";

.snackbarItem {
  position: relative;
  display: flex;
  color: $white;
  min-width: 300px;
  max-width: 600px;
  padding: 12px 13px 13px;
  margin: 0 20px;
  background-color: $dark-grey;
  border-radius: 4px;
  &_success,
  &_error,
  &_info {
    border-bottom-style: solid;
    border-bottom-width: 4px;
  }
  &_success {
    border-color: $lima;
  }
  &_error {
    border-color: $error;
  }
  &_info {
    border-color: $primary;
  }
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  &__content {
    margin-right: 1rem;
  }
  &__message {
    @include lato_font(14px, 20px, 700, $white);
    display: flex;
    flex-direction: column;
    margin-top: 2px;
  }
  &__closeButton {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 3px;
    right: 4px;
    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 12px;
      right: 6px;
      height: 1px;
      width: 12px;
      background-color: $white;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }
}
