@import "src/_mixin.scss";

.landingAppBanner {
  display: none;
  border-radius: 20px 20px 0 0;
  padding: 15px;

  @include media('mobile') {
    display: block;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
  }

  &__content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 15px 10px;
    background-color: #fff;
    z-index: 1002;
  }

  &__header {
    padding: 15px 0;
    margin-bottom: 10px;
    border-bottom: 2px solid $border-light;
    text-align: center;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  &__info {
    display: flex;
    margin-right: auto;
    align-items: center;
  }

  &__img {
    margin-right: 15px;
  }

  &__button {
    width: 100px;
  }
}
