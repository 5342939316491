@import "src/_mixin.scss";

.iconSelect {
  $labelSize: 75px;

  &_big {
    $labelSize: 100px;
  }

  width: 50%;

  &__label {
    display: block;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    & > input {
      width: 0;
      border: 0;
      visibility: hidden;
    }
  }

  &__icon {
    @include border($primary);

    display: flex;
    width: $labelSize;
    height: $labelSize;
    margin: 0 auto 10px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s;

    .svgIcon {
      transition: all 0.2s;
    }
  }

  &__label:hover &__icon {
    border-color: $dark-grey;

    .svgIcon {
      fill: $dark-grey;
    }
  }

  &__label_active &__icon,
  &__label_active:hover &__icon {
    background-color: $cta;
    border-color: $cta;

    .svgIcon {
      fill: $dark-grey;
    }
  }

  &__title {
    @include poppins_font(14px, 18px, 700, $primary);
    display: block;
    transition: color 0.2s;
  }

  &__label:hover &__title,
  &__label_active &__title,
  &__label_active:hover &__title {
    color: $text-dark-grey;
  }

  &__subLabel {
    @include lato_font(12px, 18px, 700, $text-dark-grey);
    display: block;
  }
}
