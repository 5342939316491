@import "src/_mixin.scss";

.bestRequestsNav {
  columns: 3;
  padding-left: 50px;
  margin-bottom: 200px;
  &__link {
    @include lato_font(16px, 34px, 700, $white);
    &:hover {
      color: $silver-chalice;
    }
  }
}
