@import "src/_mixin.scss";

.heading1 {
  @include poppins_font(42px, 56px, 700, $text-dark-grey);
  margin: 0;

  @include media('mobile') {
    font-size: 27px;
    line-height: 36px;
  }
}

.heading2 {
  @include poppins_font(32px, 44px, 600, $text-dark-grey);
  margin: 0;

  @include media('mobile') {
    font-size: 24px;
    line-height: 28px;
  }
}

.heading3 {
  @include poppins_font(24px, 36px, 500, $text-dark-grey);
  margin: 0;

  @include media('mobile') {
    font-size: 21px;
    line-height: 28px;
  }
}

.heading4 {
  @include lato_font(20px, 28px, 600, $text-dark-grey);
  margin: 0;

  @include media('mobile') {
    font-size: 18px;
    line-height: 24px;
  }
}
