@import "src/_mixin.scss";

button {
  border: 0;
  background: none;
  cursor: pointer;
}

.button {
  @include poppins_font(14px, 24px, 600, $text-dark-grey);
  padding: 10px 15px 9px;
  border-radius: $global-border-radius;
  outline: none;
  text-align: center;
  border: 1px solid transparent;
  transition: all 0.2s;
  cursor: pointer;
  -webkit-appearance: unset !important;
  display: inline-block;

  img {
    display: block;
  }

  svg {
    display: inline-block;
  }

  &:focus {
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $cta-disabled;
    border-color: $cta-disabled !important;
  }

  &_loading {
    position: relative;
    color: transparent;

    &::after {
      @include spinner('~common/icons/spinner.svg', 15px);
      content: '';
    }
  }

  &_fullWidth {
    width: 100%;
  }

  &_wide {
    min-width: 350px;

    @include media('mobile') {
      min-width: auto;
      width: 100%;
    }
  }

  &_cta {
    background-color: $background-cta;
    border-color: $background-cta;
    text-transform: uppercase;

    &:hover {
      background-color: $cta-hover;
      border-color: $cta-hover;
    }

    &:focus {
      background-color: $cta-hover;
      border-color: $cta-hover;
    }

    &:active {
      background-color: $cta-active;
      border-color: $cta-active;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      background-color: $background-lightest;
      border-color: $background-lightest;
      color: $text-light-grey;
    }
  }

  &_primary {
    text-transform: uppercase;
    color: #fff;
    background-color: $primary;

    &:hover {
      background-color: $primary-darker;
    }

    &:focus {
      background-color: $primary-darker;
    }

    &:active {
      background-color: $primary-darkest;
    }

    &:disabled {
      background-color: $background-lightest;
      color: $text-light-grey;
    }
  }

  &_secondary {
    text-transform: uppercase;
    border: 1px solid $primary;
    color: $primary;
    background-color: #fff;

    &:hover {
      border-color: $dark-blue;
      color: $dark-blue;
    }

    &:focus {
      border-color: $primary;
    }

    &:active {
      color: $text-dark-grey;
      border-color: $alto;
    }

    &:disabled {
      color: $text-light-grey;
    }
  }

  &_danger {
    text-transform: uppercase;
    color: #fff;
    background-color: $error;
  }

  &_icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    display: inline-block;
    margin-right: 15px;
    line-height: 1;
  }
}
