@import "src/_mixin.scss";

.allServicesNav {
  &__allServicesLink {
    display: inline-block;
    margin-top: 20px;
    padding-left: 10px;
    font-size: 14px;
    &_light {
      color: $white;
      &:hover {
        color: $silver-chalice;
      }
    }
  }
}
