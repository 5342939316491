@import "src/_mixin.scss";

.textP1 {
  @include lato_font(16px, 24px, 400, $text-dark-grey);
  margin: 0;

  &_bold {
    font-weight: 700;
  }
}

.textP2 {
  @include lato_font(15px, 22px, 400, $text-grey);
  margin: 0;

  &_bold {
    font-weight: 700;
  }
}

.textP3 {
  @include lato_font(14px, 20px, 400, $text-grey);
  margin: 0;

  &_bold {
    font-weight: 700;
  }

  &_dark {
    color: $text-dark-grey;
  }
}
