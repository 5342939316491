@import "src/_mixin.scss";

.body_user .header__olx {
  display: none;
}

.mobileNotFixedHeader .header {
  @include media('mobile') {
    margin-bottom: 0;
  }
}

.header {
  height: $desktop-header-height;

  @include media('tablet') {
    height: $mobile-header-height;
    margin-bottom: -$mobile-header-height;
  }

  &_mobileNotFixed {
    @include media('mobile') {
      margin-bottom: 0;
    }
  }

  &_hideMobile {
    @include media('mobile') {
      display: none !important;
    }
  }

  &__wrapper {
    @include border-on('bottom');
    position: relative;
    display: flex;
    justify-content: space-between;
    height: $desktop-header-height;
    background-color: $white;
    color: $dark-grey;

    &_provider {
      background-color: $dark-grey;
      color: $white;
      border: none;
    }

    &_desktopFixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
    }

    @include media('tablet') {
      position: fixed;
      height: $mobile-header-height;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;

      &_mobileNotFixed {
        position: static;
      }
    }
  }

  &__leftSide,
  &__rightSide {
    display: flex;
  }

  &__rightSide {
    padding-right: 15px;

    @include media('smallHeader') {
      padding-right: 0;
    }
  }

  &__search {
    display: flex;
    flex-grow: 1;
    max-width: 500px;
    margin-left: 15px;
    margin-right: auto;
    align-items: center;

    @include media('mobile') {
      margin-left: 10px;
    }
  }

  &__logo {
    display: flex;
    padding: 15px 22px;
    justify-content: center;
    align-items: center;
    background: $background-cta;

    &:hover,
    &:focus,
    &:active {
      border-radius: 0;
    }

    &_provider {
      padding: 15px 27px;
    }

    @include media('tablet') {
      padding: 13px 15px;
    }
  }

  &__logoImg {
    height: 40px;
    width: 85px;

    @include media('tablet') {
      height: 24px;
      width: 53px;
    }
  }

  &__logoProLabel {
    @include poppins_font(11px, 13px, 900, #fff);
    text-transform: uppercase;
    background: $dark-grey;
    border-radius: 3px;
    padding: 2px 7px;
    margin: 3px 0 0 5px;
  }

  .header__olx {
    position: absolute;
    bottom: -35px;
    left: 30px;
    color: $silver-chalice;
    font-size: 11px;
    line-height: 20px;
    z-index: 12;
    background-size: contain;
    display: inline-flex;
    align-items: center;

    &_onCategory,
    &_onCategory:hover {
      color: #fff;
      & > .header__olxLogo {
        color: #fff;
      }
    }

    &:hover {
      color: $silver-chalice;
    }

    & > svg {
      margin-left: 5px;
      color: #002f34;
    }

    @include media('mobile') {
      display: none;
    }
  }

  &__impersonate {
    position: fixed;
    box-sizing: border-box;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 18px;
    line-height: 20px;
    padding: 20px;
    border-radius: 20px;
    background: $error url('../icons/arrow_left_white.svg') left 12px center no-repeat;
    background-size: 15px;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 100;
    opacity: 0.8;
    transition: width 0.2s;

    &:hover {
      color: #fff;
      opacity: 1;
      width: 120px;
      padding: 20px 20px 20px 40px;

      .header__impersonateText {
        visibility: visible;
      }
    }
  }

  &__impersonateText {
    visibility: hidden;
  }
}
