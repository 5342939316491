@import "src/_mixin.scss";

$transitionDuration: 0.15s;
$transitionDurationMobile: 0.2s;

.Nmodal {
  position: relative;
  min-width: 600px;
  background: $white;
  transition: $transitionDuration;
  transform: translateY(15px);
  padding: 50px;
  border-radius: 4px;

  @include media('mobile') {
    margin: 0;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 65px 15px 15px;
    transform: translateX(30px);
    transition: $transitionDurationMobile;
    min-width: auto;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 50px 0;
    opacity: 0;
    transition: $transitionDuration;
    z-index: 1001;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @include media('mobile') {
      padding: 0;
      background-color: transparent;
      transition: $transitionDurationMobile;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;

    @include media('mobile') {
      position: fixed;
      left: 15px;
      right: auto;
      background: url('./img/modal_close_mobile.svg') center no-repeat;
      z-index: 10;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 35px !important;
  }
}

.ReactModal__Content {
  border-radius: $global-border-radius;

  @include focus_state;
  @include media('mobile') {
    border-radius: 0;
  }
}

.ReactModal__Overlay--after-open.Nmodal__overlay {
  opacity: 1;
  transition-delay: 60ms;
  overflow-y: auto;
}

.ReactModal__Content--after-open.Nmodal {
  transform: none;
  transition-delay: 60ms;
}

.ReactModal__Overlay--before-close.Nmodal__overlay {
  opacity: 0;
  transition: $transitionDuration;
  transition-delay: 0s;

  @include media('mobile') {
    transition: $transitionDurationMobile;
  }
}

.ReactModal__Content--before-close.Nmodal {
  transform: translateY(15px);
  transition-delay: 0s;

  @include media('mobile') {
    transform: translateX(20px) !important;
  }
}

.ReactModal__Body--open {
  @include media('mobile') {
    overflow: hidden;
    position: fixed;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
}

.noscroll {
  overflow-y: hidden;
}
