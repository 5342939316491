@import "src/_mixin.scss";

.headerSoundTrigger {
  display: flex;
  align-items: center;
  color: inherit;
  margin: 0 10px;
  @include min_media('medium') {
    margin: 0 20px 0 0;
  }

  > button {
    padding: 0;
    color: inherit;
    height: 24px;
  }
}
