@import "src/_mixin.scss";

.endpointSwitcher {
  position: fixed;
  bottom: 0;
  left: 0;
  transition: opacity .3s;
  opacity: .6;

  &:hover {
    opacity: 1;
  }

  &__relativeWrapper {
    position: relative;
  }

  &__icon {
    @include focus_state;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px 20px 20px 0;
    background: url('~common/icons/network-connection.svg') 50% 50% / 60% no-repeat;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .12);
  }

  &__switch {
    @include focus_state;
    @include lato_font(16px, 20px, 700, $text-dark-grey);
    padding: 10px;

    &:first-child {
      border-top-left-radius: 20px;
    }

    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &__floatingLabel {
    @include lato_font(18px, 22px, 700, $text-dark-grey);
    position: absolute;
    padding: 5px 8px;
    top: -40px;
    left: 0;
    background: rgba(255, 255, 255, .8);
    opacity: 0;
    transform: translateY(10px);
    transition: .3s;
    white-space: nowrap;
    pointer-events: none;
    text-shadow: none;
  }

  &__switch:hover &__floatingLabel,
  &__icon:hover &__floatingLabel {
    opacity: 1;
    transform: none;
  }
}
