@import "src/_mixin.scss";

.langSwitcher {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 14px;
  color: $text-light-grey;

  @include min_media('medium') {
    padding: 1.5rem;
    font-size: 15px;
  }
}

.langSwitcher__locale {
  margin: 0 1ch;
  color: currentColor;

  &:hover {
    color: currentColor;
  }

  &:link,
  &:visited {
    color: $white;
  }
}
