@import "src/_mixin.scss";

.backButton {
  @include focus_state;
  @include lato_font(14px, 20px, 400, $link-color);

  &::before {
    @include back_btn('~common/icons/arrow_back.svg', 16px);
    content: '';
    display: inline-block;
    height: 21px;
    margin-right: 10px;
    transition: transform .2s;
    vertical-align: top;
  }

  &:hover::before {
    transform: translateX(-5px);
  }

  &_arrowOnly:before {
    margin-right: 0;
  }
}
