.embeddedServiceHelpButton {
  z-index: 10 !important; // a conflict with cookie banner
}

.embeddedServiceSidebarButton {
  --lwc-colorTextInverse: #414141; // override color for text in button
  --lwc-colorBrandSecondaryDarken40: #ffea00; // override hover
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
  color: #414141 !important;
}

.uiButton--default {
  .label {
    text-align: center;
  }

  &:hover .label {
    color: inherit !important;
  }
}

.offlineChatMessage {
  padding: 1.5rem;
}