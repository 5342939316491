@import "src/_mixin.scss";

.input__wrap {
  display: flex;
  flex-direction: column;
}

.login {
  &__container {
    margin: auto;
    max-width: 600px;
    padding: 35px;
    border-radius: 4px;
    background-color: #fff;
  }

  &__title {
    @include poppins_font(18px, 24px, 600);

    margin: 30px 0;
    text-align: center;
  }

  &__form {
    max-width: 340px;
    margin: auto;

    &_password,
    &_code {
      text-align: center;
    }

    &_setPassword {
      max-width: 495px;
      text-align: center;
    }

    &_setPasswordSubtitle {
      margin: 0 0 35px;
      text-align: center;
      @include lato_font(16px, 24px, 400, $text-grey);
    }

    &_setPasswordHint {
      @include lato_font(13px, 17px, 400, $text-light-grey);
      margin: 15px 0 0;
    }
  }

  &__setPasswordInput {
    height: 45px;
    box-sizing: border-box;
  }

  &__forgotPassword {
    @include button_link;
    margin-bottom: 0;
    font-size: 14px !important;

    &_loading {
      @include button_link_loading('~common/icons/spinner.svg');
    }
  }

  &__resendButton {
    @include button_link;
    margin-top: 35px;
    margin-bottom: 25px;
    font-size: 15px;

    &:disabled {
      cursor: not-allowed;
      color: $silver-chalice;
    }

    &_loading {
      @include button_link_loading('~common/icons/spinner.svg');
    }
  }

  &__cancelSetPassword {
    @include button_link;
    margin-top: 35px;
    margin-bottom: 25px;
    font-size: 15px;
  }

  &__submit {
    display: block;
    margin-top: 1rem;
    margin-bottom: 10px;
  }

  &__submitPassword {
    @include button_cta;
    width: 100%;
    display: block;
    margin-top: 1rem;
    margin-bottom: 35px;
    text-transform: uppercase;

    &_loading {
      @include button_sending('~common/icons/spinner.svg', 20px);
    }
  }

  &__submitSetPassword {
    @include button_cta;
    width: 162px;
    height: 45px;
    margin-left: -5px;
    box-sizing: border-box;
    text-transform: uppercase;

    &_loading {
      @include button_sending('~common/icons/spinner.svg', 20px);
    }
  }

  &__submitCode {
    @include button_cta;
    position: relative;
    left: -5px;
    padding-top: 9px;
    padding-bottom: 8px;
    text-transform: uppercase;
    max-height: 40px;
    text-wrap: nowrap;

    &_loading {
      @include button_sending('~common/icons/spinner.svg', 20px);
    }
  }

  &__tos {
    @include lato_font(12px, 15px, 400, $text-grey);
  }

  &__codeInputWrap {
    display: flex;

    .form__row {
      flex: 1;
      margin: 0;
    }
  }

  &__setPasswordInputWrap {
    display: flex;
    justify-content: center;

    .form__row {
      flex: 1;
      margin: 0;
    }
  }

  &__loginCodeSent {
    margin-bottom: 35px;
    text-align: center;
  }

  &__resetLink {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }

  &__usernameHidden {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}

.loginModal .Nmodal__header {
  padding-top: 50px;

  @include media('mobile') {
    padding: 0 20px;
  }
}