@import "src/_mixin.scss";

.installAppBanner {
  position: relative;
  padding: 20px 10px;
  background-color: #FFF7E7;
}

.installAppBanner__close {
  position: absolute;
  right: 10px;
  top: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0;
  @include focus_state;
  border-radius: 4px;
}

.installAppBanner__times {
  @include button_close(15px);
  display: inline-block;
}

.installAppBanner__body {
  @include container;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.installAppBanner__message {
  max-width: 400px;
  min-width: 300px;
  flex-shrink: 0;
}

.installAppBanner__buttons {
  white-space: nowrap;
}

.installAppBanner__button {
  display: inline-flex;
  font-family: $LatoFont;
  text-transform: none;

  & + & {
    margin-left: 20px;
  }
}
