@import "src/_mixin.scss";

.CouponsItem {
  width: 335px;
  border: 2px solid $border-cta;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background: $white;
  overflow: hidden;

  @include media('mobile') {
    width: auto;
  }

  &__info {
    background: $white;
  }
  &__cover {
    width: 100%;
  }
  &__titleRow {
    padding: 15px;
    &_cover {
      padding-top: 0;
    }
  }
  &__logo {
    max-width: 100px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
  }
  &__title {
    display: inline;
  }

  &__descriptionRow {
    padding: 0 15px 15px;
  }
  &__actions {
    padding: 15px;
    background-color: $early-dawn;
    border-top: 2px solid $border-cta;
  }

  &__buttonHint {
    margin-bottom: 15px;
    text-align: center;
    color: #414141;
  }
  &__codeContainerWrapper {
    width: 100%;
    position: relative;
  }
  &__codeContainer,
  &__codeContainerCopyHint {
    background: $white;
    border: 2px solid $cta;
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
  &__codeContainerCopyHint {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s;
    &_active {
      opacity: 1;
    }
  }
}

.CouponsItem_linkType {
  border-color: $border-blue;
  .CouponsItem__actions {
    border-color: $border-blue;
    background-color: $white-ice;
  }
}
