.MaintenanceContainer {
  font-family: Lato, sans-serif;
  color: #676767;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  border: 1px solid #e6e6e6;
  text-align: center;

  h1 {
    font-size: 100px;
    line-height: initial;
    margin-bottom: 50px;
  }
}