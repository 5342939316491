@import "src/_mixin.scss";

.complexLoginModal {
  @include media('mobile') {
    padding-top: 50px;
  }

  &__body {
    padding-bottom: 50px;
  }
}

.complexLogin {
  &__header {
    max-width: 425px;
    margin: 0 auto 35px;
    text-align: center;
    word-break: break-word;
  }

  &__description {
    @include lato_font(15px, 20px, 400, $text-dark-grey);
    margin-top: 5px;
  }
}
