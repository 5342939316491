@import "src/_mixin.scss";

html {
  height: 100%;
}

body {
  @include lato_font(16px, 27px, 400, $text-grey);
  min-height: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media('mobile') {
    padding-top: 50px;
  }
}

.body_user {
  background-color: $body-background;
}

* {
  box-sizing: border-box;
}

#content,
#root {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @include media('mobile') {
    max-width: 100vw;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    @include focus_state;
    color: $link-color-hover;
  }

  &:active {
    color: $link-color-active;
  }
}

ul {
  list-style: none;
}

p {
  margin-top: 0;
}

.container {
  max-width: 970px;
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin: auto;
}

@for $i from 1 to 4 {
  @keyframes dotblink#{$i} {
    0% {
      opacity: 0;
    }
    #{15% * $i} {
      opacity: 0;
    }
    #{100% - 15% * (4 - $i)} {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.animatedDot {
  opacity: 1;

  @for $i from 1 to 4 {
    &__dot#{$i} {
      animation: dotblink#{$i} 2s linear 0s infinite;
    }
  }
}

input {
  -webkit-appearance: none;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  border: 0;
  background: none;
  cursor: pointer;
}

.errorContainer {
  max-width: 920px;
  margin: 100px auto;
  padding: 50px;
  border: 1px solid #e6e6e6;
  text-align: center;

  @include media('mobile') {
    margin: 0;
  }

  h1 {
    font-size: 100px;
    line-height: initial;
    margin-bottom: 50px;
  }
}

.focus_ring_hidden *:focus,
.focus_ring_hidden *:focus + .label {
  outline: none !important;
  box-shadow: none !important;
}

// screen readers only
// copied from bootstrap 5 sources
.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

// TODO: remove after experiment will be completed. Consider with @rstets
.fixly-admin-message-google-forms-wrapper {
  background-color: #fff;
  border-radius: 8px;
  padding-top: 20px;
  & > iframe {
    height: 1000px;
  }
  @include media('mobile') {
    width: 100vw;
    margin-left: -35px;
    & > iframe {
      height: 1500px;
    }
  }
}

// container to wrap and stack cookie banner, app promotion banner etc.
// fixed at screen bottom
.stickers {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
}

// When Laquesis is enabled its toolbar overlaps cookie banner cross.
// That unexpectedly brakes all automation tests,
// so we need rules below to mode panel to the left bottom corner
// and don't interrupt AQA flow

body #laquesis-qa-icon {
  left: 0;
  right: auto;
  bottom: 61px;
}
