@import "src/_mixin.scss";

.linkButton {
  @include button_link;

  &:disabled {
    color: $silver-chalice;
    cursor: not-allowed;
  }

  &_loading {
    @include button_link_loading('~common/icons/spinner.svg');
  }
}
