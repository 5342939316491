@import "src/_mixin.scss";

.complexLoginButtons {
  display: flex;
  justify-content: flex-end;
  margin: 35px 0 0;

  @include media('mobile') {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    padding: 0 10px 10px;
    z-index: 1;

    &_wizard {
      display: none;
    }
  }

  &__btn {
    width: 175px;
    margin-left: 15px;
    text-transform: uppercase;

    &_back {
      @include media('mobile') {
        margin: 0;
      }
    }
  }
}
