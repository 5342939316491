@import "src/_mixin.scss";
.changePassword {
  padding: 15px 0 25px;

  &__title {
    @include poppins_font(18px, 24px, 600);

    margin: 30px 0;
    text-align: center;
  }

  &__subtitle {
    @include poppins_font(16px, 18px, 400);

    margin: -10px 0 40px;
    text-align: center;
  }

  &__form {
    max-width: 340px;
    margin: auto;
  }

  &__submit {
    margin: 5px 0 35px;
  }

  &__text {
    text-align: center;
    line-height: 20px;
    color: $silver-chalice;
  }

  &__resetNotification {
    margin-bottom: 15px;
  }

  &__sent {
    text-align: center;
  }
}
